import axios from '@/shared/axios';
import {
    AstrolabeModel
} from '@/types/home';
import { BaseResponse } from '@/types/web.interface';

export async function fetchPdfReportData(locale?: string) {
    const res = await axios.get<BaseResponse<[AstrolabeModel]>>(`/moms/astrolabe/list`, {
        headers: {
            'Current-Language': locale,
        },
    });
    return res.data;
}