import axios from '@/shared/axios';
import { AstrolabeResultModel, AstrolabeTypeModel } from '@/types/astrolabe';
import { AudioPaymentOrderModel, OrderModel } from '@/types/home';
import { BaseResponse } from '@/types/web.interface';

export async function getAstrolabeThemesList(locale?: string) {
  const res = await axios.get<BaseResponse<[AstrolabeTypeModel]>>(`/moms/astrolabe/themes`, {
    // params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getAstrolabeResultData(taskId?: any, locale?: string) {
  const res = await axios.get<BaseResponse<AstrolabeResultModel>>(`/moms/astrolabe/get/${taskId}`, {
    // params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function buyAstrolabePdf(taskId?: any, ref?: string, locale?: string) {
  const res = await axios.get<BaseResponse<AudioPaymentOrderModel>>(`/moms/astrolabe/buy`, {
    params: {
      task_id: taskId,
    },
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function generatAstrolabeData(params: any, locale?: string) {
  const res = await axios.post<BaseResponse<AstrolabeResultModel>>(
    `/moms/astrolabe/init`,
    {
      ...(params ?? {}),
    },
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}
