// import { useTranslation } from "next-i18next"
export const isProd =
  process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes('-test.');

// App 1:DRfans, 2:Quotesecho
const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = '1' == AppType;

// name
const getAppName = () => {
  switch (AppType) {
    case '1':
      return 'DRfans';
    case '2':
      return 'Quotesecho';
    case '3':
    case '5':
      return 'Momsgpts';
    case '4':
      return 'Huntpink';
    default:
      return '';
  }
};

export const lowerName = getAppName().toLowerCase();

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.png`;
// app net logo
const getAppNetLogo = `https://gpts-prod.s3.us-west-1.amazonaws.com/logo/${lowerName}-logo.png`;
// app name
const getAppLogoName = `/images/layouts/${lowerName}-name.png`;
// app ico
const getAppICO = `/${lowerName}_favicon.ico`;
// app title
const getAppTitle = () => {
  switch (AppType) {
    case '1':
      return 'DRfans | Your Source for New Dark Romance Discoveries';
    case '2':
      return 'QuotesEcho | Discover infinite quotes and relevant GPTs at QuotesEcho';
    case '3':
      return `Mom's Hub for Tools, Baby Names & Expert Blogs | Momsgpts`;
    case '4':
      return 'HuntPink | Discover the Best in Pink Fashion & Lifestyle';
    case '5':
      return 'Conselhos de Parentalidade de Especialistas & Dicas para Mães Modernas';
    default:
      return '';
  }
};

// app desc
const getAppDesc = () => {
  switch (AppType) {
    case '1':
      return `Stay updated with the latest books, in-depth reviews, and innovative explorations in the genre. Uncover the newest treasures of dark romance with DRfans.`;
    case '2':
      return `Explore a curated collection of inspiring quotes, delve into their profound meanings. A treasure trove for quote enthusiasts and seekers of wisdom.`;
    case '3':
      return `Essential tools, unique baby name generator, and expert blogs for moms. Discover everything you need in your motherhood journey.`;
    case '4':
      return `Find everything pink! Fashion, decor, and more. Your pink lifestyle starts here.`;
    case '5':
      return 'Descubra dicas, informações de saúde e apoio para cada etapa da maternidade no Momsgpts.';
    default:
      return ``;
  }
};

// app sub title
const getAppHeadSubTitle = () => {
  switch (AppType) {
    case '1':
      return `Advanced GPTs in Dark Romance`;
    case '2':
      return `Advanced GPTs in Quotesecho`;
    case '3':
      return `Advanced GPTs in Momsgpts`;
    case '4':
      return `Advanced GPTs in Huntpink`;
    case '5':
      return `GPTs Avançados em Momsgpts`;
    default:
      return '';
  }
};

export const currentMomsHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (!window.location.host.includes('-test.')) {
    return `www.momsgpts.com`;
  } else {
    return `www-test.drfans.com`;
  }
};

//
const getFirebaseConfig = () => {
  switch (AppType) {
    case '1':
      return {
        apiKey: 'AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw',
        authDomain: 'gpts-factory.firebaseapp.com',
        projectId: 'gpts-factory',
        storageBucket: 'gpts-factory.appspot.com',
        messagingSenderId: '375183826106',
        appId: '1:375183826106:web:4931ea60fcedccb5d5f683',
        measurementId: 'G-65SZP316P3',
      };
    case '2':
      return {
        apiKey: 'AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw',
        authDomain: 'gpts-factory.firebaseapp.com',
        projectId: 'gpts-factory',
        storageBucket: 'gpts-factory.appspot.com',
        messagingSenderId: '375183826106',
        appId: '1:375183826106:web:613cb91e7f078399d5f683',
        measurementId: 'G-0HECRFPL8E',
      };
    case '3':
    case '5':
      return {
        apiKey: 'AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw',
        // authDomain: 'gpts-factory.firebaseapp.com',
        authDomain: `${currentMomsHost()}/fb-auth`,
        projectId: 'gpts-factory',
        storageBucket: 'gpts-factory.appspot.com',
        messagingSenderId: '375183826106',
        appId: '1:375183826106:web:0b81a90a8888c3fbd5f683',
        measurementId: 'G-7TX1K2XV3T',
      };
    case '4':
      return {
        apiKey: 'AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw',
        authDomain: 'gpts-factory.firebaseapp.com',
        projectId: 'gpts-factory',
        storageBucket: 'gpts-factory.appspot.com',
        messagingSenderId: '375183826106',
        appId: '1:375183826106:web:6cb7d0065c05673bd5f683',
        measurementId: 'G-E477H376F2',
      };
    // case '5':
    //   return {
    //     apiKey: 'AIzaSyDDZQ6AqZbl8YOC8f-3P_0tNCxa72Ge8Lw',
    //     authDomain: 'gpts-factory.firebaseapp.com',
    //     projectId: 'gpts-factory',
    //     storageBucket: 'gpts-factory.appspot.com',
    //     messagingSenderId: '375183826106',
    //     appId: '1:375183826106:web:f5d2e451e42e6d2fd5f683',
    //     measurementId: 'G-FCLYH1NTZ0',
    //   };
    default:
      return {};
  }
};

// link
const getClarityId = () => {
  switch (AppType) {
    // case '1':
    // case '2':
    case '3':
      return 'ljzszprtjy';
    // case '4':
    // case '5':
    default:
      return '';
  }
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  namePath: getAppLogoName,
  icoPath: getAppICO,
  title: getAppTitle(),
  headSubTitle: getAppHeadSubTitle(),
  desc: getAppDesc(),
  firebaseConfig: getFirebaseConfig(),
  clarityId: isProd ? getClarityId() : 'l9q3tnv9s4',
};

export const letterList = [
  {
    title: 'A',
    link: `/baby-name-search?letter=A`,
  },
  {
    title: 'B',
    link: `/baby-name-search?letter=B`,
  },
  {
    title: 'C',
    link: `/baby-name-search?letter=C`,
  },
  {
    title: 'D',
    link: `/baby-name-search?letter=D`,
  },
  {
    title: 'E',
    link: `/baby-name-search?letter=E`,
  },
  {
    title: 'F',
    link: `/baby-name-search?letter=F`,
  },
  {
    title: 'G',
    link: `/baby-name-search?letter=G`,
  },
  {
    title: 'H',
    link: `/baby-name-search?letter=H`,
  },
  {
    title: 'I',
    link: `/baby-name-search?letter=I`,
  },
  {
    title: 'J',
    link: `/baby-name-search?letter=J`,
  },
  {
    title: 'K',
    link: `/baby-name-search?letter=K`,
  },
  {
    title: 'L',
    link: `/baby-name-search?letter=L`,
  },
  {
    title: 'M',
    link: `/baby-name-search?letter=M`,
  },
  {
    title: 'N',
    link: `/baby-name-search?letter=N`,
  },
  {
    title: 'O',
    link: `/baby-name-search?letter=O`,
  },
  {
    title: 'P',
    link: `/baby-name-search?letter=P`,
  },
  {
    title: 'Q',
    link: `/baby-name-search?letter=Q`,
  },
  {
    title: 'R',
    link: `/baby-name-search?letter=R`,
  },
  {
    title: 'S',
    link: `/baby-name-search?letter=S`,
  },
  {
    title: 'T',
    link: `/baby-name-search?letter=T`,
  },
  {
    title: 'U',
    link: `/baby-name-search?letter=U`,
  },
  {
    title: 'V',
    link: `/baby-name-search?letter=V`,
  },
  {
    title: 'W',
    link: `/baby-name-search?letter=W`,
  },
  {
    title: 'X',
    link: `/baby-name-search?letter=X`,
  },
  {
    title: 'Y',
    link: `/baby-name-search?letter=Y`,
  },
  {
    title: 'Z',
    link: `/baby-name-search?letter=Z`,
  },
  // {
  //   title: 'All',
  //   link: `/baby-name-search?letter=other-letters`,
  // },
];

export const commonImgPath = `https://res-front.pumpsoul.com/moms-prod/images`;
