import axios from '@/shared/axios';
import {
  AudioLyricsModel,
  AudioModel,
  AudioPayItemModel,
  AudioPaymentOrderModel,
} from '@/types/home';
import { BaseResponse } from '@/types/web.interface';

export async function getBabyAudioKeywordList(locale?: string) {
  const res = await axios.get<BaseResponse<[string]>>(`/moms/music/keywords`, {
    // params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getBabyAudioStyles(locale?: string) {
  const res = await axios.get<BaseResponse<[string]>>(`/moms/music/styles`, {
    // params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getBabyAudioList(locale?: string) {
  const res = await axios.get<BaseResponse<[AudioModel]>>(`/moms/music/list`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getBabyAudio(locale?: string, songId?: any) {
  const res = await axios.get<BaseResponse<AudioModel>>(`/moms/music/get/${songId}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

/*
title	string	
必须
标题	
music_tag	string	
非必须
风格	
lyrics_content	string	
必须
*/
export async function generatBabyAudioData(
  title: any,
  music_tag: any,
  lyrics_content: any,
  song_id: any,
  locale?: string,
) {
  const res = await axios.post<BaseResponse<{ task_id?: string }>>(
    `/moms/music/generate`,
    {
      title,
      music_tag,
      lyrics_content,
      song_id,
    },
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// theme	string 主题
// baby_name	string 宝宝名称
export async function generatBabyAudioLyricsData(theme: any, baby_name: any, locale?: string) {
  const res = await axios.post<BaseResponse<AudioLyricsModel>>(
    `/moms/music/lyrics/generate`,
    {
      theme,
      baby_name,
    },
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

export async function renameBabyAudioData(song_id: any, title: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(
    `/moms/music/rename`,
    {
      song_id,
      title,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

export async function getAudioPaymentItems(locale?: string) {
  const res = await axios.get<BaseResponse<[AudioPayItemModel]>>(`/moms/song/items`, {
    // params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function babyAudioBuyRequest(
  item_id: any,
  redirect_url: any,
  needPay: boolean = false,
  theme?: any,
  locale?: string,
) {
  const res = await axios.post<BaseResponse<AudioPaymentOrderModel>>(
    `/moms/song/buy/sub`,
    {
      item_id,
      redirect_url,
      need_pay: needPay,
      theme,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}
